import Vue from "vue";
import Components from "./components";
import "./scss/app.scss";
import "./js/app.js";


// Font Awesome Icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faAngleRight, faChevronDown, faAngleLeft, faAngleUp, faPhone, faFax, faEnvelope, faMapMarkerAlt, faGraduationCap, faClipboardList, faAward, faLink, faChevronLeft, faChevronRight, faUserMd } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf, faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faTwitter, faInstagram, faFlipboard, faLinkedin, faYoutube} from '@fortawesome/free-brands-svg-icons'
// import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add( faCircle, faAngleLeft, faAngleRight, faChevronDown, faAngleUp, faPhone, faFax, faEnvelope, faMapMarkerAlt, faFacebook, faTwitter, faInstagram, faFlipboard, faLinkedin, faYoutube, faGraduationCap, faAddressCard, faClipboardList, faAward, faFilePdf, faLink, faChevronLeft, faChevronRight, faUserMd)

dom.watch()
// Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.component('font-awesome-layers', FontAwesomeLayers);


// Lazysizes lazy loading.
// https://github.com/aFarkas/lazysizes
import "lazysizes/lazysizes.js"
import "lazysizes/plugins/bgset/ls.bgset"

// Animate On Scroll Options
// https://github.com/michalsnik/aos
import AOS from 'aos';
AOS.init({duration: 800});


// Import Bootstrap dependencies
import "bootstrap/js/dist/util.js";
// import "bootstrap/js/dist/alert.js";
// import "bootstrap/js/dist/button.js";
import "bootstrap/js/dist/carousel.js";
// import "bootstrap/js/dist/collapse.js";
// import "bootstrap/js/dist/dropdown.js";
// import "bootstrap/js/dist/modal.js";
// import "bootstrap/js/dist/tooltip.js";
// import "bootstrap/js/dist/popover.js";
// import "bootstrap/js/dist/scrollspy.js";
// import "bootstrap/js/dist/tab.js";
// import "bootstrap/js/dist/index.js";

import "bootstrap/dist/js/bootstrap"

import $ from 'jquery'
window.jQuery = window.$ = $;

import 'slick-carousel'



// Vue.config.productionTip = false;
//
// new Vue({
//   el: "#app",
//   delimiters: ["${", "}"]
// });
