<template>
    <div class="swiper" v-swiper="swiperOptions">
        <div class="swiper-wrapper">
            <div class="swiper-slide mb-5" v-for="testimonial in testimonials" :key="testimonial.id">
                <blockquote>
                    <div v-html="testimonial.text" class="mb-2"></div>
                    <div class="quote-name">—
                        {{ testimonial.name }}
                    </div>
                </blockquote>
            </div>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
    </div>
</template>
<script>
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'

    export default {
        name: "TestimonialSlider",
        props: ["testimonials"],
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                    }
                }
            }
        },
    }
</script>

<style scoped>
</style>
