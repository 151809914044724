// Show made by Spaceray Creative banner.
if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    var n = ["\n %c Made with ♥ by Spaceray Creative %c %c %c https://www.spaceraycreative.com/ \n\n", "color: #a0d39d; background: #0b4061; padding:5px 0;", "background: #4c4c4d; padding:5px 0;", "background: #4c4c4d; padding:5px 0;", "color: #fff; background: #1c1c1c; padding:5px 0;"];
    window.console.log.apply(console, n)
} else
    window.console && window.console.log("Made with love ♥ by Spaceray Creative - https://www.spaceraycreative.com/")

// Pause/play service videos on hover.
$(document).ready(function () {
    var figure = $(".video").hover(hoverVideo, hideVideo);

    function hoverVideo(e) {
        $('video', this).get(0).play();
    }
    function hideVideo(e) {
        $('video', this).get(0).pause();
    }
});

// Medium Zoom plugin -- Zoom image effect on images)
// https://github.com/francoischalifour/medium-zoom
import mediumZoom from 'medium-zoom'

mediumZoom('[data-zoomable]', {
    margin: 24,
    background: '#fff',
    scrollOffset: 0
})

// Swiper plugin
// https://swiperjs.com/
import Swiper from "swiper/js/swiper"
let mySwiper = new Swiper('.swiper-container', {
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    }
})

// Magnific gallery plugin
// https://github.com/dimsemenov/photoswipe
import "magnific-popup/dist/jquery.magnific-popup"
$('.zoom-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    image: {
        verticalFit: true
    },
    gallery: {
        enabled: true
    },
    zoom: {
        enabled: true,
        duration: 300, //  Also change the duration in CSS
        opener: function (element) {
            return element.find('img');
        }
    }
});

// Newsletter form submit function
$('newsletter-form').on("submit", function (event) {
    event.preventDefault();

    $.ajax({
        url: '/', type: "POST", data: $(this).serialize(), dataType: "json", success: function (data) {
            if (!data.success) {
                // there was an error, do something with data
            } else {
                // Success
            }
        }
    })
});

// Social Media Sharing Window
var socialShares = document.querySelectorAll('.social-share a');
if (socialShares) {
    [].forEach.call(socialShares, function (anchor) {
        anchor.addEventListener('click', function (e) {
            var url = this.href,
                width = 500,
                height = 300,
                left = screen.width / 2 - width / 2,
                top = screen.height / 2 - height / 2;

            if (/^(f|ht)tps?:\/\//i.test(url) || /^mailto/i.test(url)) {
                e.preventDefault();
                window.open(
                    url,
                    '',
                    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' +
                    width +
                    ',height=' +
                    height +
                    ',top=' +
                    top +
                    ',left=' +
                    left,
                );
            }
        });
    });
}

